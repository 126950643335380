import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"


const get_AsetPenjualanList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.PENJUALAN.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const get_AsetPenjualanDetail = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.PENJUALAN.DETAIL, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_AssetPenjualanSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.PENJUALAN.SAVE, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
  get_AsetPenjualanList,
  get_AsetPenjualanDetail,
  post_AssetPenjualanSave,
}